import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import { withRouter } from "react-router-dom";
// import TopbarNotification from "./topbarNotification";
// import TopbarMessage from "./topbarMessage";
// import TopbarSearch from "./topbarSearch";
// import TopbarUser from "./topbarUser";
// import TopbarAddtoCart from "./topbarAddtoCart";
import TopbarWrapper from "./topbar.style";
import { last } from "lodash";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
  render() {
    const {
      toggleCollapsed,
      url,
      customizedTheme,
      publicRoute,
      style = {},
      history
    } = this.props;

    const { pathname } = history.location;
    const routeName = last(String(pathname).split("/"));
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70,
      ...style
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            publicRoute || collapsed
              ? `isomorphicTopbar collapsed ${publicRoute ? "public" : ""}`
              : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            {!publicRoute && (
              <button
                className={
                  collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                }
                style={{ color: customizedTheme.textColor }}
                onClick={toggleCollapsed}
              />
            )}
            <span className="url">{routeName.replace(/-/g, " ")}</span>
          </div>

          {/* <ul className="isoRight">
            <li className="isoSearch">
              <TopbarSearch locale={locale} />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "notification" })}
              className="isoNotify"
            >
              <TopbarNotification locale={locale} />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "message" })}
              className="isoMsg"
            >
              <TopbarMessage locale={locale} />
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "addToCart" })}
              className="isoCart"
            >
              <TopbarAddtoCart url={url} locale={locale} />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser locale={locale} />
            </li>
          </ul> */}
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme
  }),
  { toggleCollapsed }
)(withRouter(Topbar));
