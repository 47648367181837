// import getDevSidebar from "../../customApp/sidebar";

const options = [
  {
    key: "",
    label: "Dashboard",
    leftIcon: "ion-android-apps"
  },
  {
    key: "projects",
    label: "Projects",
    leftIcon: "ion-folder"
  },
  {
    key: "shared-spaces",
    label: "Shared spaces",
    leftIcon: "ion-android-folder-open",
    children: [
      {
        key: "shared-spaces",
        label: "Share spaces",
      },
      {
        key: "single-shared-spaces",
        label: "Single Shared",
        withoutDashboard: true,
      },
    ]
  },
  {
    key: "images-gallery",
    label: "Images gallery",
    leftIcon: "ion-images",
    children: [
      {
        key: "images-gallery",
        label: "All projects"
      },
      {
        key: "images-gallery/single-project",
        label: "Single"
      },
      {
        key: "shared-gallery",
        label: "Shared",
        withoutDashboard: true
      }
    ]
  },
  {
    key: "settings",
    label: "Settings",
    leftIcon: "ion-settings"
  },
  {
    key: "projects-settings",
    label: "Projects settings",
    leftIcon: "ion-ios-cog"
  },
  {
    key: "shared-space-settings",
    label: "Shared space settings",
    leftIcon: "ion-android-people"
  },
  {
    key: "user-management",
    label: "User Management",
    leftIcon: "ion-ios-people"
  }, // end-demo

  // {
  //   key: "mailbox",
  //   label: "email",
  //   leftIcon: "ion-android-mail"
  // },
  // {
  //   key: "chat",
  //   label: "chat",
  //   leftIcon: "ion-chatbubbles"
  // },
  // {
  //   key: "ecommerce",
  //   label: "ecommerce",
  //   leftIcon: "ion-bag",
  //   children: [
  //     {
  //       key: "shop",
  //       label: "shop"
  //     },
  //     {
  //       key: "cart",
  //       label: "cart"
  //     },
  //     {
  //       key: "checkout",
  //       label: "checkout"
  //     },
  //     {
  //       key: "card",
  //       label: "cards"
  //     }
  //   ]
  // },
  // {
  //   key: "maps",
  //   label: "maps",
  //   leftIcon: "ion-map",
  //   children: [
  //     {
  //       key: "googlemap",
  //       label: "googleMap"
  //     },
  //     {
  //       key: "leafletmap",
  //       label: "leafletMap"
  //     }
  //   ]
  // },
  // {
  //   key: "invoice",
  //   label: "invoice",
  //   leftIcon: "ion-clipboard"
  // },
  // {
  //   key: "youtubeSearch",
  //   label: "youtubeSearch",
  //   leftIcon: "ion-social-youtube"
  // },
  // {
  //   key: "calendar",
  //   label: "calendar",
  //   leftIcon: "ion-calendar"
  // },
  // {
  //   key: "notes",
  //   label: "notes",
  //   leftIcon: "ion-ios-paper"
  // },
  // {
  //   key: "todo",
  //   label: "todos",
  //   leftIcon: "ion-android-checkbox-outline"
  // },
  // {
  //   key: "firestorecrud",
  //   label: "firestorecrud",
  //   leftIcon: "ion-fireball",

  //   children: [
  //     {
  //       key: "articles",
  //       label: "firestorecrudarticle"
  //     },
  //     {
  //       key: "investors",
  //       label: "firestorecrudinvestor"
  //     }
  //   ]
  // },
  // {
  //   key: "contacts",
  //   label: "contacts",
  //   leftIcon: "ion-android-person-add"
  // },
  // {
  //   key: "shuffle",
  //   label: "shuffle",
  //   leftIcon: "ion-grid"
  // },
  // {
  //   key: "charts",
  //   label: "charts",
  //   leftIcon: "ion-arrow-graph-up-right",
  //   children: [
  //     {
  //       key: "googleChart",
  //       label: "googleCharts"
  //     },
  //     {
  //       key: "reecharts",
  //       label: "recharts"
  //     },
  //     {
  //       key: "reactChart2",
  //       label: "reactChart2"
  //     },
  //     {
  //       key: "frappeChart",
  //       label: "frappeChart"
  //     }
  //   ]
  // },
  // {
  //   key: "Forms",
  //   label: "forms",
  //   leftIcon: "ion-android-mail",
  //   children: [
  //     {
  //       key: "InputField",
  //       label: "input"
  //     },
  //     {
  //       key: "editor",
  //       label: "editor"
  //     },
  //     {
  //       key: "FormsWithValidation",
  //       label: "formsWithValidation"
  //     },
  //     {
  //       key: "progress",
  //       label: "progress"
  //     },
  //     {
  //       key: "button",
  //       label: "button"
  //     },
  //     {
  //       key: "tab",
  //       label: "tab"
  //     },
  //     {
  //       key: "checkbox",
  //       label: "checkbox"
  //     },
  //     {
  //       key: "radiobox",
  //       label: "radiobox"
  //     },
  //     {
  //       key: "selectbox",
  //       label: "selectbox"
  //     },
  //     {
  //       key: "transfer",
  //       label: "transfer"
  //     },
  //     {
  //       key: "autocomplete",
  //       label: "autocomplete"
  //     }
  //   ]
  // },
  // {
  //   key: 'gridLayout',
  //   label: 'boxOptions',
  //   leftIcon: 'ion-cube'
  // },
  // {
  //   key: "uielements",
  //   label: "uiElements",
  //   leftIcon: "ion-leaf",
  //   children: [
  //     {
  //       key: "op_badge",
  //       label: "badge"
  //     },
  //     {
  //       key: "op_card",
  //       label: "card2"
  //     },
  //     {
  //       key: "op_carousel",
  //       label: "corusel"
  //     },
  //     {
  //       key: "op_collapse",
  //       label: "collapse"
  //     },
  //     {
  //       key: "op_popover",
  //       label: "popover"
  //     },
  //     {
  //       key: "op_tooltip",
  //       label: "tooltip"
  //     },
  //     {
  //       key: "op_tag",
  //       label: "tag"
  //     },
  //     {
  //       key: "op_timeline",
  //       label: "timeline"
  //     },
  //     {
  //       key: "dropdown",
  //       label: "dropdown"
  //     },
  //     {
  //       key: "pagination",
  //       label: "pagination"
  //     },
  //     {
  //       key: "rating",
  //       label: "rating"
  //     },
  //     {
  //       key: "tree",
  //       label: "tree"
  //     }
  //   ]
  // },
  // {
  //   key: "advancedUielements",
  //   label: "advancedElements",
  //   leftIcon: "ion-flash",
  //   children: [
  //     {
  //       key: "reactDates",
  //       label: "reactDates"
  //     },
  //     {
  //       key: "codeMirror",
  //       label: "codeMirror"
  //     },
  //     {
  //       key: "uppy",
  //       label: "uppy"
  //     },
  //     {
  //       key: "dropzone",
  //       label: "dropzone"
  //     }
  //   ]
  // },
  // {
  //   key: "feedback",
  //   label: "feedback",
  //   leftIcon: "ion-thumbsup",
  //   children: [
  //     {
  //       key: "alert",
  //       label: "alert"
  //     },
  //     {
  //       key: "modal",
  //       label: "modal"
  //     },
  //     {
  //       key: "message",
  //       label: "message"
  //     },
  //     {
  //       key: "notification",
  //       label: "notification"
  //     },
  //     {
  //       key: "popConfirm",
  //       label: "popConfirm"
  //     },
  //     {
  //       key: "spin",
  //       label: "spin"
  //     }
  //   ]
  // },
  // {
  //   key: "table",
  //   label: "tables",
  //   leftIcon: "ion-android-menu",
  //   children: [
  //     {
  //       key: "table_ant",
  //       label: "antTables"
  //     }
  //   ]
  // },
  // {
  //   key: "pages",
  //   label: "pages",
  //   leftIcon: "ion-document-text",
  //   children: [
  //     {
  //       key: "404",
  //       label: "404",
  //       withoutDashboard: true
  //     },
  //     {
  //       key: "500",
  //       label: "500",
  //       withoutDashboard: true
  //     },
  //     {
  //       key: "signin",
  //       label: "signIn",
  //       withoutDashboard: true
  //     },
  //     {
  //       key: "signup",
  //       label: "signUp",
  //       withoutDashboard: true
  //     },
  //     {
  //       key: "forgotpassword",
  //       label: "forgotPw",
  //       withoutDashboard: true
  //     },
  //     {
  //       key: "resetpassword",
  //       label: "resetPw",
  //       withoutDashboard: true
  //     }

  //     // {
  //     //   key: 'comingSoon',
  //     //   label: 'comingSoon',
  //     //    withoutDashboard: true
  //     // }
  //   ]
  // },
  // {
  //   key: "scrum-board",
  //   label: "scrumboard",
  //   leftIcon: "ion-android-checkbox-outline"
  // },
  // ...getDevSidebar
];
export default options;
